import React from "react";
import Img from "gatsby-image";
import PortableText from "../portableText";

const InformationBody = (props) => {
  return (
    <div className="min-vh-100 blog__grid">
      <div className="mw8 serif f4 lh-copy center pa2 article__container">
        {props.mainImage && (
          <div>
            <Img
              className="w-100 mw8 h-100 mt3 mb3"
              fluid={props.mainImage.asset.fluid}
              alt={props.mainImage.alt}
            />
            <p className="f5 tc i lh-copy">{props.mainImage.caption}</p>
          </div>
        )}
        <div className="tj">
          <PortableText blocks={props.body} />
        </div>
      </div>
    </div>
  );
};

export default InformationBody;
