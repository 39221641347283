import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/common/layout";
import BreadCrumbs from "../components/common/breadcrumbs";
import InformationTitle from "../components/information/information-title";
import InformationBody from "../components/information/information-body";

export const query = graphql`
  query NewsTemplateQuery($id: String!) {
    news: sanityNews(id: { eq: $id }) {
      id
      publishedAt(locale: "id", formatString: "dddd, DD MMM YYYY")
      title
      _rawBody(resolveReferences: { maxDepth: 5 })
      mainImage {
        asset {
          id
        }
        alt
        caption
      }
    }
  }
`;

const TemplateNews = (props) => {
  const { data } = props;
  const news = data && data.news;

  return (
    <Layout>
      <BreadCrumbs
        parentPath="/berita"
        parentName="informasi"
        lastPath="/berita"
        lastName="berita"
        currentPage={news.title}
      />
      <InformationTitle title={news.title} publishedAt={news.publishedAt} />
      <InformationBody mainImage={news.mainImage} body={news._rawBody} />
    </Layout>
  );
};

export default TemplateNews;
