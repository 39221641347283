import React from "react";
import { Link } from "gatsby";

const BreadCrumbs = (props) => {
  return (
    <div className="mw8 pa2 center f7 mt5 mb3 tracked ttu sans-serif">
      <Link to={props.parentPath} className="dark-gray">
        {props.parentName}
      </Link>
      <span>&nbsp;&gt;&nbsp;</span>
      <Link to={props.lastPath} className="dark-gray">
        {props.lastName}
      </Link>
      <span>&nbsp;&gt;&nbsp;</span>
      <span className="mid-gray">{props.currentPage}</span>
    </div>
  );
};

export default BreadCrumbs;
