import React from "react";

const InformationTitle = (props) => {
  return (
    <div className="pv4 mw8 pa2 center">
      <h1 className="fw1 display dark-gray db w-100 mw7 f2-ns">
        {props.title}
      </h1>
      <p className="db f6 silver ttu tracked sans-serif">{props.publishedAt}</p>
    </div>
  );
};

export default InformationTitle;
